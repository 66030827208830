import React from "react";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts
import AuthLayout from "./layouts/Layout";

// Guards

import Page404 from "./pages/error/Page404";
import Page500 from "./pages/error/Page500";
import Order from "./pages/order";
import CustomerInfoForm from "./pages/order/CustomerInfoForm";
import PaymentForm from "./pages/order/PaymentForm";
import PaymentResult from "./pages/order/PaymentResult";
import OrderConfirmationEft from "./pages/order/OrderConfirmationEFT";
import OrderConfirmationPreviousOrderUpdate from "./pages/order/OrderConfirmationPreviousOrderUpdate";

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Order />,
        children: [
          {
            path: "",
            element: <CustomerInfoForm />,
          },
          {
            path: "payment",
            element: <PaymentForm />,
          },
          {
            path: "payment-result",
            element: <PaymentResult />,
          },
          {
            path: "payment-result-previous-order-update",
            element: <OrderConfirmationPreviousOrderUpdate />,
          },
          {
            path: "eft",
            element: <OrderConfirmationEft />,
          },
        ],
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
