import axios from "../../utils/axios";
import { Formik, useFormikContext } from "formik";
import {
  Alert as MuiAlert,
  AlertTitle,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { useCustomerPaymentInfo } from "./index";
import { useNavigate } from "react-router-dom";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ceil, isString } from "lodash";
import qs from "qs";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

export type PaymentInfoType = {
  currency: string;
  cardNumber: string;
  cvc: string;
  expiryDate: string;
  card_holder_name: string;
  amount?: string;
};

const Form = () => {
  const { values: customerInfo } = useCustomerPaymentInfo();
  const {
    values,
    setValues,
    handleSubmit,
    touched,
    errors,
    handleBlur,
    handleChange,
  } = useFormikContext<PaymentInfoType>();

  const {
    meta: { erroredInputs, touchedInputs },
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({
    autoFocus: false,
  });

  const testCases = (amount: number) => [
    {
      status: "Success",
      cardType: "MasterCard",
      cardNumber: "5123456789012346",
      expiry: "12/50",
      cvc: "123",
      name: "Jim Test",
      amount: ceil(amount),
    },
    {
      status: "Declined",
      cardType: "MasterCard",
      cardNumber: "5123456789012346",
      expiry: "12/50",
      cvc: "123",
      name: "Jim Test",
      amount: ceil(amount) + 0.12,
    },
    {
      status: "50s timeout",
      cardType: "MasterCard",
      cardNumber: "5123456789012346",
      expiry: "12/50",
      cvc: "123",
      name: "Jim Test",
      amount: 111.99,
    },
    {
      status: "Success",
      cardType: "MasterCard 2 Series",
      cardNumber: "2720010040360012",
      expiry: "12/50",
      cvc: "123",
      name: "Jim Test",
      amount: ceil(amount),
    },
    {
      status: "Declined",
      cardType: "MasterCard 2 Series",
      cardNumber: "2720010040360012",
      expiry: "12/50",
      cvc: "123",
      name: "Jim Test",
      amount: ceil(amount) + 0.12,
    },
    {
      status: "50s timeout",
      cardType: "MasterCard 2 Series",
      cardNumber: "2720010040360012",
      expiry: "12/50",
      cvc: "123",
      name: "Jim Test",
      amount: 111.99,
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name="amount"
        label="Amount"
        value={values.amount}
        error={Boolean(touched.amount && errors.amount)}
        fullWidth
        helperText={touched.amount && errors.amount}
        onBlur={handleBlur}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />

      <TextField
        name="card_holder_name"
        label="Card Holder Name"
        value={values.card_holder_name}
        error={Boolean(touched.card_holder_name && errors.card_holder_name)}
        fullWidth
        helperText={touched.card_holder_name && errors.card_holder_name}
        onBlur={handleBlur}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />

      <TextField
        label="Card Number"
        fullWidth
        error={Boolean(
          (touchedInputs.cardNumber || touched.cardNumber) &&
            (erroredInputs.cardNumber || errors.cardNumber)
        )}
        helperText={
          (touchedInputs.cardNumber || touched.cardNumber) &&
          (erroredInputs.cardNumber ?? errors.cardNumber)
        }
        onBlur={handleBlur}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {/* @ts-ignore */}
              <svg {...getCardImageProps({ images })} />
            </InputAdornment>
          ),
        }}
        inputProps={getCardNumberProps({
          value: values.cardNumber,
        })}
      />

      <Grid container spacing={12}>
        <Grid item xs={6}>
          <TextField
            label="Expiry"
            fullWidth
            error={Boolean(
              (touchedInputs.expiryDate || touched.expiryDate) &&
                (erroredInputs.expiryDate || errors.expiryDate)
            )}
            helperText={
              (touchedInputs.expiryDate || touched.expiryDate) &&
              (erroredInputs.expiryDate ?? errors.expiryDate)
            }
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            value={values.expiryDate}
            inputProps={getExpiryDateProps()}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="CVC"
            fullWidth
            error={Boolean(
              (touchedInputs.cvc || touched.cvc) &&
                (erroredInputs.cvc || errors.cvc)
            )}
            helperText={
              (touchedInputs.cvc || touched.cvc) &&
              (erroredInputs.cvc ?? errors.cvc)
            }
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            value={values.cvc}
            inputProps={getCVCProps()}
          />
        </Grid>
      </Grid>

      <Button type="submit" variant="contained" color="primary" mt={3}>
        Pay
      </Button>

      {Boolean(customerInfo?.testMode) && (
        <TableContainer component={Paper} sx={{ mt: 6 }}>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell align="right">Card Type</TableCell>
                <TableCell align="right">Card #</TableCell>
                <TableCell align="right">Expiry</TableCell>
                <TableCell align="right">CVC</TableCell>
                <TableCell align="right">Choose</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {testCases(Number(values.amount))?.map((testCase, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {testCase.status}
                    </TableCell>
                    <TableCell align="right">{testCase.cardType}</TableCell>
                    <TableCell align="right">{testCase.cardNumber}</TableCell>
                    <TableCell align="right">{testCase.expiry}</TableCell>
                    <TableCell align="right">{testCase.cvc}</TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          setValues({
                            card_holder_name: testCase.name,
                            cardNumber: testCase.cardNumber,
                            cvc: testCase.cvc,
                            expiryDate: testCase.expiry,
                            currency: "AUD",
                            amount: testCase.amount.toString(),
                          });
                        }}
                      >
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </form>
  );
};

const PaymentForm = () => {
  const navigate = useNavigate();
  const { values: customerInfo } = useCustomerPaymentInfo();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const initialValues: PaymentInfoType = {
    currency: "AUD",
    cardNumber: "",
    cvc: "",
    expiryDate: "",
    card_holder_name: "",
    amount: customerInfo?.amount?.toString(),
  };

  const validationSchema = Yup.object().shape({
    currency: Yup.string().required("Required"),
    cardNumber: Yup.string().required("Required"),
    cvc: Yup.string().required("Required"),
    expiryDate: Yup.string().required("Required"),
    card_holder_name: Yup.string().required("Required"),
    amount: Yup.number()
      .min(Number(customerInfo?.amount) || 0)
      .max(Number(customerInfo?.totalQuoteAmount) || 0)
      .required("Required"),
  });

  useEffect(() => {
    if (customerInfo?.authKey === undefined) {
      navigate("/");
    }
  }, [customerInfo]);

  const handleSubmit = async (
    values: PaymentInfoType,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    if (!executeRecaptcha) {
      console.error(
        "handleSubmit() was called even though executeRecaptcha is not yet available"
      );
      return;
    }

    try {
      await axios.post("/api/verify-recaptcha-token", {
        "g-recaptcha-response": await executeRecaptcha(
          "PaymentForm_handleSubmit"
        ),
      });
    } catch (error: any) {
      let msg = "Something went wrong. Please try a again.";
      if (isString(error)) msg = error;
      else if (error?.message) msg = error?.message;

      setStatus({ sent: false });
      setErrors({ submit: msg });
      setSubmitting(false);
      return;
    }

    const expiryDate = values.expiryDate.split("/").map((v) => v.trim());

    let result: any = null;
    try {
      result = await new Promise((resolve, reject) => {
        performance.mark("CBA.ProcessPayment:started");
        console.log("CBA.ProcessPayment started");

        // @ts-ignore
        CBA.ProcessPayment({
          AuthKey: customerInfo?.authKey,
          Crn1: customerInfo?.merchantReference,
          Crn2: `${customerInfo?.firstName} ${customerInfo?.lastName}`, // firstName + lastName
          Crn3: customerInfo?.totalQuoteAmount, // depositAmount
          MerchantReference: customerInfo?.merchantReference,
          EmailAddress: customerInfo?.email,
          Currency: values.currency,
          CardNumber: values.cardNumber,
          Cvn: values.cvc,
          ExpiryMonth: expiryDate[0],
          ExpiryYear: expiryDate[1],
          Amount: values?.amount ?? 0,
          CardHolderName: values.card_holder_name,
          CallbackFunction: (result: any) => {
            const measurement = performance.measure(
              "CBA.ProcessPayment:duration",
              {
                start: `CBA.ProcessPayment:started`,
              }
            );

            if (measurement.duration > 6000) {
              console.log(
                "CBA.ProcessPayment took longer than expected (6s):",
                `${measurement.duration.toFixed(2)} ms`
              );
            }

            console.log("CBA.ProcessPayment result:", result);

            if (result.AjaxResponseType == 0) {
              console.log("AJAX call successful");
              //AJAX call was successful
              if (result.ApiResponseCode == 0) {
                console.log("API returned success:", result);
                //API returned success.
                //Refer to (API Response Codes) for API Response codes.
                //Submit result.ResultKey to your server for further
                //processing (Refer Transaction Result)
                resolve(result);
              } else if (result.ApiResponseCode == 300) {
                console.log("Redirection required, API Response Code 300");
                if (
                  result.RedirectionUrl != null &&
                  result.RedirectionUrl.length > 0
                ) {
                  setTimeout(() => {
                    window.location.href = result.RedirectionUrl;
                  }, 5000);
                } else {
                  console.error("Redirection URL is not valid");
                  reject(result);
                }
              } else {
                console.error("API Response Code indicates failure:", result);
                reject(result);
              }
            } else if (result.AjaxResponseType == 1) {
              console.error("Error with AJAX call:", result);
              reject(result);
            } else if (result.AjaxResponseType == 2) {
              console.error("AJAX call timed out:", result);
              reject(result);
            }
          },
        });
      });

      const url = new URL(result.RedirectionUrl);

      console.log(
        "Navigating to payment result page with query params:",
        url.search
      );

      setTimeout(() => {
        navigate(`/payment-result${url.search}`);
      }, 5000);
    } catch (errors: any) {
      if (errors.AjaxResponseType === 0 && errors.ApiResponseCode === 206) {
        // Session not found
        console.log(
          "Session not found, redirecting with initialValues:",
          customerInfo
        );

        setTimeout(() => {
          navigate(
            `/${qs.stringify(
              {
                initialValues: customerInfo,
              },
              { addQueryPrefix: true }
            )}`
          );
        }, 1000);
      }

      console.error("CBA.ProcessPayment failed", { errors });

      setStatus({ sent: false });
      setErrors(errors);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, isSubmitting }) => (
        <Card my={6}>
          <Typography component="h1" variant="h1" align="center">
            Credit Card Payment
          </Typography>

          <CardContent>
            {Boolean(customerInfo?.testMode) && (
              <Alert severity="warning" my={4}>
                <AlertTitle>
                  TEST MODE{" "}
                  {!!customerInfo?.testModeReason &&
                    `: ${customerInfo?.testModeReason}`}
                </AlertTitle>
                Payments will be marked as TEST mode.
              </Alert>
            )}

            {/* @ts-ignore */}
            {errors && errors.submit && (
              <Alert severity="error" my={3}>
                {/* @ts-ignore */}
                {errors.submit}
              </Alert>
            )}

            {/* @ts-ignore */}
            {errors?.Errors?.map((err: any, index) => (
              <Alert severity="error" my={3} key={index}>
                {/* @ts-ignore */}
                {err.Message}
              </Alert>
            ))}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <Form />
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default PaymentForm;
