export const mainApiBaseUrl = import.meta.env.ALDRIN_ERP_MAIN_API_URL ?? "";

export const apiBaseURL = import.meta.env.API_URL ?? "http://localhost:3012";

export const reCaptchaSiteKey =
  import.meta.env.RECAPTCHA_SITE_KEY ??
  "6LcXg44jAAAAAEZZg0XXhvLrSrEMRAhy-59HQHBm";

export const openobserveDisabled =
  import.meta.env.OPENOBSERVE_RUM_DISABLED === "true";

export const openobserveRumInit = {
  clientToken: import.meta.env.OPENOBSERVE_RUM_CLIENT_TOKEN,
  site: import.meta.env.OPENOBSERVE_RUM_SITE,
  organizationIdentifier:
    import.meta.env.OPENOBSERVE_RUM_ORGANIZATION || "default",
};
