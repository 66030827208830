import axios from "axios";
import qs from "qs";
import { apiBaseURL } from "../config";

const axiosInstance = axios.create({
  baseURL: apiBaseURL,
  paramsSerializer: (params) => qs.stringify(params),
});

export default axiosInstance;
