import { useEffect, useRef } from "react";
import { openobserveRum } from "@openobserve/browser-rum";
import { LogsEvent, openobserveLogs } from "@openobserve/browser-logs";
import { openobserveDisabled, openobserveRumInit } from "../config";

const options = {
  ...openobserveRumInit,
  applicationId: "aerp-pportal-frontend",
  service: "aerp-pportal-frontend",
  env: import.meta.env.MODE,
  version: BUILD_VERSION,
  insecureHTTP: import.meta.env.MODE === "development",
  apiVersion: "v1",
};

export const useOpenObserveInit = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (openobserveDisabled || !options.clientToken || initialized.current) {
      return;
    }

    openobserveRum.init({
      applicationId: options.applicationId, // required, any string identifying your application
      clientToken: options.clientToken,
      site: options.site,
      organizationIdentifier: options.organizationIdentifier,
      service: options.service,
      env: options.env,
      version: options.version,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      apiVersion: options.apiVersion,
      insecureHTTP: options.insecureHTTP,
      defaultPrivacyLevel: "allow", // 'allow' or 'mask-user-input' or 'mask'. Use one of the 3 values.,
      // beforeSend: (event: RumEvent, context: RumEventDomainContext) => {
      //   console.log({ event });
      // }
    });

    openobserveLogs.init({
      clientToken: options.clientToken,
      site: options.site,
      organizationIdentifier: options.organizationIdentifier,
      service: options.service,
      env: options.env,
      version: options.version,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ["error", "info", "log"],
      insecureHTTP: options.insecureHTTP,
      apiVersion: options.apiVersion,
      beforeSend: (event: LogsEvent) => {
        // console.log("beforeSend logs", event);
        return ![
          "console error: AxiosError: Request failed with status code 401",
        ].includes(event?.message ?? "");
      },
    });

    initialized.current = true;
  }, []);
};
