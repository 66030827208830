import { Alert as MuiAlert, Card as MuiCard, CardContent } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useNavigate, useSearchParams } from "react-router-dom";
import OrderConfirmationMessage from "./OrderConfirmationMessage";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);

const OrderConfirmationPreviousOrderUpdate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ResultKey = searchParams.get("ResultKey");
  const transaction_for = searchParams.get("transaction_for");
  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const { mutate, error, data, isError, isLoading, isSuccess } =
  //   useSubmitResultKey();
  //
  // useEffect(() => {
  //   if (!executeRecaptcha) {
  //     return;
  //   }
  //
  //   if (!ResultKey) {
  //     navigate("/");
  //     return;
  //   }
  //
  //   executeRecaptcha("PaymentResult_submitResultKey").then(
  //     (recaptchaResponse) => {
  //       mutate({
  //         ResultKey,
  //         transaction_for: transaction_for ?? "deposit",
  //         "g-recaptcha-response": recaptchaResponse,
  //       });
  //     }
  //   );
  // }, [ResultKey, executeRecaptcha]);

  return (
    <Card my={6}>
      <CardContent>
        {/*{isLoading && (*/}
        {/*  <Box display="flex" justifyContent="center" my={6}>*/}
        {/*    <CircularProgress />*/}
        {/*  </Box>*/}
        {/*)}*/}

        {/*{isError && (*/}
        {/*  <Alert severity="error" my={3}>*/}
        {/*    {get(error, "response.data.message", "Something went wrong!")}*/}
        {/*  </Alert>*/}
        {/*)}*/}

        <OrderConfirmationMessage />
      </CardContent>
    </Card>
  );
};

export default OrderConfirmationPreviousOrderUpdate;
