import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  Box,
  Card as MuiCard,
  CircularProgress,
  Paper,
  Stack,
} from "@mui/material";
import { Outlet, useOutletContext, useSearchParams } from "react-router-dom";
import { CustomerInfoType } from "./CustomerInfoForm";
import { useScript } from "usehooks-ts";
import { spacing } from "@mui/system";
import qs from "qs";

const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  margin-top: ${(props) => props.theme.spacing(20)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export type CustomerPaymentInfoType = CustomerInfoType & {
  amount?: number;
  authKey?: string;
  testMode?: boolean;
  testModeReason?: string;
};

function Order() {
  const [values, setValues] = React.useState<CustomerPaymentInfoType>({
    firstName: "",
    lastName: "",
    email: "",
    merchantReference: "",
    totalQuoteAmount: 0,
    paymentMethod: "credit-card",
    transaction_for: "",
  });

  const [searchParams] = useSearchParams();
  const parsedSearchParams = useMemo(
    () => qs.parse(searchParams.toString()),
    [searchParams]
  );

  useEffect(() => {
    const customerInfo = parsedSearchParams?.customerInfo as any;
    if (customerInfo) {
      // we have to do it like this
      // because the testMode response is sometimes boolean or string "true" or "false"
      const testMode =
        customerInfo?.testMode?.toString().toLowerCase() === "true";

      setValues({
        ...customerInfo,
        testMode,
      });
    }
  }, [parsedSearchParams]);

  const status = useScript("https://www.bpoint.com.au/webapi/CBA/api.js?v=3");

  useEffect(() => {
    if (status === "error") {
      console.error(
        "Payment script did not load: https://www.bpoint.com.au/webapi/CBA/api.js?v=3",
        Object.fromEntries(searchParams.entries())
      );
    }
  }, [status, searchParams]);

  return (
    <React.Fragment>
      <Wrapper>
        <Helmet>
          <title>Accept</title>
        </Helmet>
        {status === "ready" ? (
          <Outlet context={{ values, setValues }} />
        ) : status === "error" ? (
          <Card my={6}>
            <Stack
              direction="column"
              spacing={6}
              justifyContent="space-between"
              alignItems="center"
            >
              <img src="/icon128x128.svg" alt="logo" width="30%" />
              <Alert severity="error">
                Sorry this service is not available at this time and are aware
                that some of our customers are currently experiencing issues
                processing online payments via BPOINT. <br />
                <br />
                This is due to technical issues experienced by our bank and we
                are working to resolve this as soon as possible. <br />
                <br />
                Thank you for your patience.
              </Alert>
            </Stack>
          </Card>
        ) : (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        )}
      </Wrapper>
    </React.Fragment>
  );
}

export default Order;

type ContextType = {
  values?: CustomerPaymentInfoType;
  setValues: Dispatch<SetStateAction<CustomerPaymentInfoType>>;
};

export function useCustomerPaymentInfo() {
  return useOutletContext<ContextType>();
}
